<template>
  <div class="admin">
    <h1 class="title">Formulaire de connexion</h1>
    <form @submit.prevent="login" class="login-form">
      <div class="form-row">
        <label for="email" class="form-label">Email</label>
        <input type="email" v-model="admin.email" name="email" id="email" class="form-input" required>
      </div>
      <div class="form-row">
        <label for="password" class="form-label">Mot de passe</label>
        <input type="password" v-model="admin.password" name="password" id="password" class="form-input" required>
      </div>
      <div class="form-btn">
        <button type="submit" class="btn">Envoyer</button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admin: {
        email: '',
        password : ''
      }
    }
  },
  methods: {
    login() {
      this.$emit('login', this.admin)
      this.admin.email = ''
      this.admin.password = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .login-form {
    min-width: 100%;
    .form-row {
      flex-direction: column;
      align-items: center;
      .form-input {
        width: 90%;
      }
    }
  }
  .form-label {
    margin-bottom: 5px;
  }
}

.login {
  margin-top: 20px;
}

.login-form {
  width: 66%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.form-label {
  flex: 1;
  font-weight: 300;
  font-size: large;
}

.form-input {
  font-family: "Roboto", sans-serif;
  font-size: medium;
  flex: 3;
  border: 1px solid #dcdde1;
  border-radius: 2px;
  padding: 5px;
  background-color: #f8f8f8;
  &:hover {
    border-color: #093145;
    transition: 0.5s;
  }
  &:required:valid {
    border: 1px solid #dcdde1;
  }
  &:focus:required:invalid {
    border-color: red;
    box-shadow: none;
  }
  &:invalid {
    box-shadow: none;
  }
}

.form-btn {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  .btn {
    font-family: "Roboto", sans-serif;
    font-size: medium;
    border: 1px solid #dcdde1;
    background-color: #f8f8f8;
    padding: 5px 10px 5px 10px;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
      border-color: #093145;
      transition: 0.5s;
    }
  }
}
</style>